import { useSearchParams, useAction, useSubmission, createAsync } from '@solidjs/router';
import { Match, Switch, createEffect, onMount, Suspense, Show } from 'solid-js';
import { Container, Page, ActivityIndicator, Heading } from '@troon/ui';
import { captureException } from '@sentry/solidstart';
import { Icon } from '@troon/icons';
import dayjs from '@troon/dayjs';
import { SupportButton } from '../../../../../../components/support';
import { gql, useMutation } from '../../../../../../graphql';
import { dayTimeToDate } from '../../../../../../modules/date-formatting';
import { Receipt } from '../../../../../access/checkout/_components/receipt';
import { confirmSubscriptionMutation } from '../../../../../../components/confirm-subscription';
import NotFound from '../../../../404';
import { cachedQuery } from '../../../../../../graphql/cached-get';
import { useGolfer } from '../../../_providers/golfer';
import type { RouteDefinition } from '@solidjs/router';

const artificialTimeoutMs = 2_000;

export default function ConfirmationPage() {
	const [params] = useSearchParams<{ subscriptionId?: string; productId?: string; confirmed?: string }>();
	const golfer = useGolfer();

	const confirmSubmissionAction = useMutation(confirmSubscriptionMutation);
	const confirmSubscription = useAction(confirmSubmissionAction);
	const confirmation = useSubmission(confirmSubmissionAction);

	const associateInfo = createAsync(
		async () =>
			await (confirmation.result?.data ? getAssociateInfo({ subscriptionId: params.subscriptionId! }) : undefined),
	);

	createEffect(() => {
		if (confirmation.result?.error) {
			captureException(confirmation.result.error);
		}
	});

	onMount(async () => {
		if (!params.subscriptionId) {
			return;
		}
		const data = new FormData();
		data.set('subscriptionId', params.subscriptionId);
		setTimeout(async () => {
			await confirmSubscription(data);
		}, artificialTimeoutMs);
	});

	return (
		<Show when={params.subscriptionId && params.productId} fallback={<NotFound />}>
			<Page>
				<Container size="xsmall">
					<Suspense>
						<Switch
							fallback={<ActivityIndicator class="size-16 text-brand">Confirming Subscription</ActivityIndicator>}
						>
							<Match when={confirmation.result?.error}>
								{(error) => (
									<>
										<p>
											{error()
												.graphQLErrors.map(({ message }) => message)
												.join('')}
										</p>
										<SupportButton />
									</>
								)}
							</Match>
							<Match when={confirmation.result?.data}>
								{(data) => (
									<Show when={associateInfo()?.associateTroonAccessInfo}>
										{(info) => (
											<div class="flex flex-col gap-8">
												<div class="flex flex-col items-center gap-6 text-center">
													<Icon name="circle-check-custom" class="text-8xl" />
													<Heading as="h1" size="h2">
														{golfer.firstName}’s {data().confirmation.subscriptionName} subscription is now active!
													</Heading>
													<p class="text-lg">
														We’ve sent a confirmation email to <code class="text-nowrap">{info().email}</code>.
													</p>
												</div>
												<div class="flex flex-col gap-4 rounded border border-neutral p-6">
													<Heading as="h2" size="h5">
														Order Summary
													</Heading>
													<p>
														<Switch>
															<Match when={data().confirmation.autoRenews && data().confirmation.nextInvoiceDate}>
																{(nextInvoiceDate) => (
																	<>
																		Your membership will renew automatically on{' '}
																		{dayjs(dayTimeToDate(nextInvoiceDate())).format('MM/DD/YYYY')}.{' '}
																	</>
																)}
															</Match>
															<Match when={data().confirmation.endDayTime}>
																{(endDayTime) => (
																	<>
																		Your membership will not auto-renew and will end on{' '}
																		{dayjs(dayTimeToDate(endDayTime())).format('MM/DD/YYYY')}.{' '}
																	</>
																)}
															</Match>
														</Switch>
														{golfer.firstName} can manage their membership through their account settings at{' '}
														<code class="font-mono">https://troon.com/account</code>.
													</p>
													<Receipt
														discount={data().confirmation.discountAmount}
														subtotal={data().confirmation.subtotalAmount}
														tax={data().confirmation.taxAmount}
														total={data().confirmation.totalAmount}
													/>
												</div>

												<Show when={info().accessRate}>
													<div class="flex flex-col gap-4 rounded border border-neutral p-6">
														<Heading as="h2" size="h5">
															Reservation Updates
														</Heading>

														<p>Please update {golfer.firstName}’s reservation rate accordingly:</p>

														<table class="w-full">
															<tbody>
																<tr>
																	<th scope="row" class="text-start">
																		Public rate
																	</th>
																	<td class="text-end line-through">{info().publicRate?.displayValue}</td>
																</tr>
																<tr>
																	<th scope="row" class="text-start">
																		Access rate
																	</th>
																	<td class="text-end">{info().accessRate?.displayValue}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Show>
											</div>
										)}
									</Show>
								)}
							</Match>
						</Switch>
					</Suspense>
				</Container>
			</Page>
		</Show>
	);
}

const associateQuery = gql(`query associateAccessInfo($subscriptionId: String!) {
	associateTroonAccessInfo(troonCardSubscriptionId: $subscriptionId) {
		publicRate { ...Currency }
		accessRate { ...Currency }
		email
	}
}`);

const getAssociateInfo = cachedQuery(associateQuery);

export const route = { info: { nav: 'minimal' } } satisfies RouteDefinition;
